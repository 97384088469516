import configuration from '~/configuration'
import { getCookie, setCookie } from '~/core/generic/cookies'

const THEME_KEY = `theme`

export const DARK_THEME_CLASSNAME = `dark`
export const LIGHT_THEME_CLASSNAME = `light`
export const SYSTEM_THEME_CLASSNAME = 'system'
export const DEFAULT_THEME = configuration.theme

export function getStoredTheme() {
  if (typeof document === 'undefined')
    return DEFAULT_THEME
  try {
    return getCookie(THEME_KEY) ?? DEFAULT_THEME
  }
  catch (error) {
    console.error('Failed to get stored theme:', error)
    return DEFAULT_THEME
  }
}

export function setTheme(theme: string | null) {
  if (typeof document === 'undefined')
    return

  const root = document.documentElement
  root.classList.remove(DARK_THEME_CLASSNAME, LIGHT_THEME_CLASSNAME)

  if (theme === DARK_THEME_CLASSNAME || (theme === SYSTEM_THEME_CLASSNAME && isDarkSystemTheme())) {
    root.classList.add(DARK_THEME_CLASSNAME)
  }

  setCookie(THEME_KEY, theme || DEFAULT_THEME)
}

export function isDarkSystemTheme() {
  if (typeof window === 'undefined')
    return false
  return window.matchMedia('(prefers-color-scheme: dark)').matches
}

export function loadSelectedTheme() {
  if (typeof document === 'undefined')
    return
  setTheme(getStoredTheme())
}
